import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Tool Type",
      name: "tool_type",
      placeholder: "Select tool type",
      required: true,
      rules: [
        {
          required: true,
          message: "Tool type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Model",
      name: "model",
      required: true,
      placeholder: "Enter model",
      rules: [
        {
          required: true,
          message: "Model is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Condition",
      name: "condition",
      required: true,
      placeholder: "Enter condition",
      rules: [
        {
          required: true,
          message: "Condition is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Manufacturer",
      name: "manufacturer",
      placeholder: "Select manufacturer",
      required: true,
      rules: [
        {
          required: true,
          message: "Condition is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      required: true,
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Tool Incharge",
      name: "used_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Used by is required!",
        },
      ],
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});

import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          const responseData = response?.data?.data;
          console.log("data", response?.data);

          const totalQuantity =
            responseData?.attributes?.purchase_order?.data?.attributes?.material_items?.find(
              (subItem) =>
                subItem?.raw_data?.material_item ===
                responseData?.attributes?.material_item?.data?.id
            )?.quantity;

          const totalReceivedQuantity =
            responseData?.attributes?.received_quantity -
            responseData?.attributes?.return_replace_quantity;

          setData({
            ...responseData?.attributes,
            id: responseData?.id,
            remaining_quantity: totalQuantity - totalReceivedQuantity,
            newly_received_quantity: 0,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <AddForm config={config} data={data} loading={loading} />;
};

export default Page;

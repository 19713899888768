import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Invoice from",
      dataIndex: "invoice_from",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      selectable: true,

    },

  ],
  ...config,
};

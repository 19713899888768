import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";
import { isEmpty } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    once_answered: [true, false]?.map((item) => ({
      value: item,
      label: item === true ? "Yes" : "No",
    })),
    distribution_list: ["Complaint", "Delay", "Query"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          assigned_to: data,
          verified_by: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor: data,
        })),
    },
    {
      slug: "cost-codes",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          cost_code: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          let newData = {
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            due_date: moment(response?.data?.data?.attributes?.due_date),
            replied_date: moment(
              response?.data?.data?.attributes?.replied_date
            ),
            project: response?.data?.data?.attributes?.project?.data?.id,
            assigned_to:
              response?.data?.data?.attributes?.assigned_to?.data?.id,
            verified_by:
              response?.data?.data?.attributes?.verified_by?.data?.id,
            cost_code: response?.data?.data?.attributes?.cost_code?.data?.id,
            sub_contractor:
              response?.data?.data?.attributes?.sub_contractor?.data?.id,
          };

          if (
            isEmpty(newData?.drawing_portion) ||
            isEmpty(newData?.drawing_portion?.data)
          ) {
            newData["drawing_portion"] = null;
          }

          if (
            isEmpty(newData?.drawing_rfi) ||
            isEmpty(newData?.drawing_rfi?.data)
          ) {
            newData["drawing_rfi"] = null;
          }

          setData(newData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;

import React, { useEffect, useState } from 'react'
import { axios } from '../../../../../App';
import { CHART_SCHEMAS, CHART_SLUGS } from '../constants';
import { fetchData } from '../../../../../utils/helpers/data.helper';
import { ReportDataTable } from '../../../components';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Col, Table } from 'antd';
import ChartGenerator from '../../../../../components/ChartGenerator';

const DashboardReport = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [projects, setProjects] = useState([])

    const fetchProjects = () => {
        fetchData({
            slug: "projects",
            query: {
                populate: "*",
                sort: ["start_date:desc"],
                pagination: {
                    page: 1,
                    pageSize: 10,
                },
                filters: {
                    status: "Active",
                },
            },
            onSuccess: (data) => setProjects(data),
        });
    };

    const fetchChartData = async (inputProjectId, inputPeriodId) => {
        let requests = [];
        let newData = {};
        let slug = "";

        for (let slug of Object.values(CHART_SLUGS)) {
            requests.push(
                axios.get(
                    `/quick-projects-dashboard/${slug}`
                )
            );
        }

        const response = await Promise.all(requests);

        for (let i = 0; i < Object.values(CHART_SLUGS)?.length; i++) {
            slug = Object.values(CHART_SLUGS)?.[i];

            newData[slug] = response[i]?.data;
        }

        setData(newData);
    };

    const refreshData = () => {
        fetchProjects()
        fetchChartData();
    };

    useEffect(() => {
        fetchProjects()
        fetchChartData();
    }, []);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',

        },
        {
            title: 'End Date',
            dataIndex: 'end_date',

        },
        {
            title: 'Project type',
            dataIndex: 'project_type',

        },
        {
            title: 'Status',
            dataIndex: 'status',

        },
    ];

    const table_data =
        projects?.map((project) => ({
            name: project.name,
            start_date: project.start_date,
            end_date: project.end_date,
            project_type: project.project_type.data.attributes.name,
            status: project.status
        }))

    console.log('dashboard loaded', data, projects, columns)

    return (
        <Box>
            {/* <div id="barGraph">
          <Bar {...barConfig} />
        </div> */}

            <ReportDataTable
                data={table_data}
                config={{
                    slugs: {
                        plural: 'dashboard-report'
                    },
                    pageTitle: 'Dashboard Report',
                    pie_title: '',
                    columns: [...columns]
                }}
                actions={{
                    onRefresh: refreshData,
                }}
                Toolbar={
                    <div></div>
                }

                onBack={() => navigate(`/quick-projects/reports`)}
            />

            <Table
                columns={columns}
                dataSource={table_data}
                rowKey={(record) => record.date} // Use a unique key for each row, assuming date is unique
            />

            <Col span={24} id='barGraph'>
                <ChartGenerator
                    type="grouped-columns"
                    schema={CHART_SCHEMAS.TIMELINE_AND_RESOURCES}
                    data={data?.[CHART_SLUGS.TIMELINE_AND_RESOURCES]}
                />
            </Col>

            <Col span={24} id='pieChart'>
                {data?.[CHART_SLUGS.PHASE_COMPLETION] && (
                    <ChartGenerator
                        type="donus-group"
                        schema={CHART_SCHEMAS.PHASE_COMPLETION}
                        data={data?.[CHART_SLUGS.PHASE_COMPLETION]}
                    />
                )}
            </Col>

            <div style={{ display: 'flex', gap: '20px' }} id='pieChart2'>
                <div style={{ flex: '1'}}>
                    {data?.[CHART_SLUGS.COST_PERFORMANCE] && (
                        <ChartGenerator
                            type="pie"
                            schema={CHART_SCHEMAS.COST_PERFORMANCE}
                            data={data?.[CHART_SLUGS.COST_PERFORMANCE]}
                        />
                    )}
                </div>

                <div style={{ flex: '1'}}>
                    {data?.[CHART_SLUGS.PERFORMANCE_REVIEWS] && (
                        <ChartGenerator
                            type="pie"
                            schema={CHART_SCHEMAS.PERFORMANCE_REVIEWS}
                            data={data?.[CHART_SLUGS.PERFORMANCE_REVIEWS]}
                        />
                    )}
                </div>
            </div>

        </Box>
    )
}

export default DashboardReport
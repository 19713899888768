import config from "../config";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Material Item",
      dataIndex: "material_item",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    // {
    //   title: "Material Unit",
    //   dataIndex: "material_unit",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      selectable: true,
    },
    {
      title: "Received Quantity",
      dataIndex: "received_quantity",
      selectable: true,
    },
    {
      title: "Remaining Quantity",
      dataIndex: "remaining_quantity",
      selectable: true,
    },
    // {
    //   title: "Order Date",
    //   dataIndex: "order_date",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.order_date).unix() - moment(b.order_date).unix(),
    //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    //   width: 250,
    // },
    // {
    //   title: "Received Date",
    //   dataIndex: "received_date",
    //   selectable: true,
    //   sorter: (a, b) =>
    //     moment(a.received_date).unix() - moment(b.received_date).unix(),
    //   render: (text) => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    //   width: 250,
    // },
  ],
  ...config,
};

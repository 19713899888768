import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import AddCoordinate from "../AddCoordinate";
import EditCoordinate from "../EditCoordinate";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-setup",
      title: "Home",
    },
    {
      path: `/quick-setup/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 24,
      component: "input",
      label: "Name",
      name: "name",
      required: true,
      placeholder: "Enter name",
      rules: [
        {
          required: true,
          message: "Name is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      required: true,
      placeholder: "Enter PIN code",
      rules: [
        {
          required: true,
          message: "PIN code is required!",
        },
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location Type",
      name: "location_type",
      placeholder: "Select location type",
      required: true,
      rules: [
        {
          required: true,
          message: "Location type is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
      required: true,
      rules: [
        {
          required: true,
          message: "State is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
      required: true,
      rules: [
        {
          required: true,
          message: "City is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      required: true,
      placeholder: "Enter address line 1",
      rules: [
        {
          required: true,
          message: "Address line 1 is required!",
        },
      ],
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      required: true,
      placeholder: "Enter address line 2",
      rules: [
        {
          required: true,
          message: "Address line 2 is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Latitude",
      name: "latitude",
      required: true,
      placeholder: "Enter latitude",
      rules: [
        {
          required: true,
          message: "Latitude is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Longitude",
      name: "longitude",
      required: true,
      placeholder: "Enter longitude",
      rules: [
        {
          required: true,
          message: "Longitude is required!",
        },
      ],
      min: 0,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "repeatable",
      label: "Coordinates",
      name: "coordinates",
      list: [
        {
          name: "Latitude",
          dataIndex: "latitude",
        },
        {
          name: "Longitude",
          dataIndex: "longitude",
        },
      ],
      modalComponents: {
        add: (params) => {
          return <AddCoordinate {...params} />;
        },
        edit: (params) => {
          return <EditCoordinate {...params} />;
        },
      },
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-setup/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            ...data,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});

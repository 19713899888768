import { gql } from '@apollo/client';

const COSTREPORTS = gql`
  query CostReports($startDate: Date, $endDate: Date) {
    projects {
      data {
        attributes {
          no
          name
          uid
          estimated_budget
          sanctioned_budget
          status
          start_date
          end_date
          project_type {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }

    employees {
      data {
        attributes {
          first_name
          last_name
          basic_salary
        }
      }
    }

    tasks(filters: { start_date: { gte: $startDate }, end_date: { lte: $endDate } }, pagination: { pageSize: 9999 }) {
      data {
        attributes {
          name
          estimated_budget
          sanctioned_budget
        }
      }
    }

    taskManpowers {
      data {
        attributes {
          labour
        }
      }
    }

    taskMachines {
      data {
        attributes {
          quantity
          machine {
            data {
              attributes {
                name
                initial_value
                loan_balance
              }
            }
          }
        }
      }
    }

    agencyDebitNotes {
      data {
        attributes {
          grand_total
          heading
          status
        }
      }
    }

    vendorDebitNotes {
      data {
        attributes {
          grand_total
          heading
          status
        }
      }
    }

    subContractorDebitNotes {
      data {
        attributes {
          grand_total
          heading
          status
          sub_contractor {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }

    subContractorPaymentSchedules {
      data {
        attributes {
          amount
          scheduled_date
          sub_contractor {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }

    vendorPaymentSchedules {
      data {
        attributes {
          amount
          scheduled_date
          vendor {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }

    agencyPaymentSchedules {
      data {
        attributes {
          amount
          scheduled_date
          agency {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default COSTREPORTS;

import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { find } from "lodash";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Approved"]?.map((item) => ({
      value: item,
      label: item,
    })),
    material_item: [],
    all_material_item: [],
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
        uid: item?.uid,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
         // approved_by: data,
        })),
    },
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "material-items",
      query: {
        populate: "*",
        pagination: {
          limit: 999,
        },
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_material_item: data,
        })),
    },
    {
      slug: "material-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_unit: data,
        })),
    },
    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      mode={props?.mode}
      data={{
        ...props?.data,
        status: "Pending",
        employee: find(values?.employee, {
          uid: auth?.user?.uid,
        })?.value,
      }}
      actions={props?.actions}
      disabledItems={props?.disabledItems}
      onFieldChange={{
        material_group: (antForm) => {
          const materialGroupId = antForm.getFieldValue("material_group");

          antForm.setFieldValue("material_item", undefined);

          if (!materialGroupId) {
            setValues((prev) => ({
              ...prev,
              material_item: [],
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              material_item: values?.all_material_item
                ?.filter(
                  (item) => item?.material_group?.data?.id === materialGroupId
                )
                ?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
            }));
          }
        },
      }}
    />
  );
};

export default Page;

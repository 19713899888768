import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Button, Space } from "antd";
import { UserTypes } from "../constants";
import { Stack } from "@chakra-ui/react";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Active", "Inactive"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [userTypeId, setUserTypeId] = useState(-1);
  const [showForm, setShowForm] = useState(false);
  const contentTypes = [
    {
      slug: "app-roles",
      query: {
        filters: {
          user_type: {
            $in: [UserTypes?.[userTypeId]?.name, "General"],
          },
        },
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          app_roles: data,
        })),
    },
  ];

  useEffect(() => {
    let schemas = [...contentTypes];

    // eslint-disable-next-line default-case
    switch (userTypeId) {
      case 0: {
        schemas?.push({
          slug: "employees",
          onSuccess: (data) => {
            setValues((prev) => ({
              ...prev,
              employee: data?.map((item) => ({
                value: item?.id,
                label: `${item?.first_name} ${item?.last_name}`,
              })),
            }));
          },
        });
        break;
      }

      case 1: {
        schemas?.push({
          slug: "partners",
          onSuccess: (data) => {
            setValues((prev) => ({
              ...prev,
              partner: data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })),
            }));
          },
        });

        break;
      }

      case 2: {
        schemas?.push({
          slug: "agencies",
          onSuccess: (data) => {
            setValues((prev) => ({
              ...prev,
              agency: data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })),
            }));
          },
        });

        break;
      }

      case 3: {
        schemas?.push({
          slug: "sub-contractors",
          onSuccess: (data) => {
            setValues((prev) => ({
              ...prev,
              sub_contractor: data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })),
            }));
          },
        });

        break;
      }

      case 4: {
        schemas?.push({
          slug: "vendors",
          onSuccess: (data) => {
            setValues((prev) => ({
              ...prev,
              vendor: data?.map((item) => ({
                value: item?.id,
                label: item?.name,
              })),
            }));
          },
        });

        break;
      }
    }

    schemas?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [userTypeId]);

  if (!showForm) {
    return (
      <div>
        <h2 style={styles.pageTitle}>Choose User Type</h2>

        <Stack direction={{base: "column", md: "row"}} spacing={4}>
          {UserTypes?.map((userType, index) => (
            <div
              style={{
                ...styles.card,
                borderColor: index === userTypeId ? "#3E3EF4" : "#eee",
              }}
              key={userType?.id}
              onClick={() => setUserTypeId(index)}
            >
              <img src={userType?.image} alt="" style={styles.cardImage} />
              <h3
                style={{
                  ...styles.cardTitle,
                  color: index === userTypeId ? "#3E3EF4" : "#111",
                }}
              >
                {userType?.name}
              </h3>
            </div>
          ))}
        </Stack>

        <Space style={styles.buttons}>
          <Button onClick={config?.actions?.onBack}>Cancel</Button>

          <Button
            type="primary"
            onClick={() => setShowForm(true)}
            disabled={userTypeId === -1}
          >
            Next
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <AddForm
      config={{
        ...config,
        title: `${config?.title} (${UserTypes?.[userTypeId]?.name})`,
        pageTitle: `${config?.title} (${UserTypes?.[userTypeId]?.name})`,
        breadcrumbs: config?.breadcrumbs?.map((breadcrumb, index) => {
          if (index === config?.breadcrumbs?.length - 1) {
            breadcrumb[
              "title"
            ] = `${breadcrumb?.title} (${UserTypes?.[userTypeId]?.name})`;
          }

          return breadcrumb;
        }),
      }}
      values={values}
      data={{
        userType: UserTypes?.[userTypeId]?.name,
      }}
    />
  );
};

const styles = {
  cards: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: 24,
  },
  card: {
    border: "2px solid #eee",
    textAlign: "center",
    padding: 24,
    width: "100%",
    borderRadius: 12,
    cursor: "pointer",
  },
  cardImage: {
    width: 64,
    height: 64,
    margin: "0 auto",
    marginBottom: 12,
  },
  buttons: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
  },
  cardTitle: {
    fontSize: 15,
    fontWeight: "bold",
  },
  pageTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 12,
  },
};

export default Page;

import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Pending", "Active", "On Hold", "Completed"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project_managers: data,
          members: data,
          // can_update_gantt_chart: data,
          // can_view_gantt_chart: data,
        })),
    },
    {
      slug: "app-users",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.username} (${item?.user_type})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          can_update_gantt_chart: data,
          can_view_gantt_chart: data,
          can_update_project: data,
          can_view_project: data,
        })),
    },
    {
      slug: "partners",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          partners: data,
        })),
    },
    // {
    //   slug: "vendors",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       vendors: data,
    //     })),
    // },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractors: data,
        })),
    },
    // {
    //   slug: "agencies",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       agencies: data,
    //     })),
    // },
    {
      slug: "project-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "companies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          company: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        console.log('ressssss', response)

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            members: response?.data?.data?.attributes?.members?.data?.map(
              (item) => item?.id
            ),
            project_managers:
              response?.data?.data?.attributes?.project_managers?.data?.map(
                (item) => item?.id
              ),
            partners: response?.data?.data?.attributes?.partners?.data?.map(
              (item) => item?.id
            ),
            can_update_gantt_chart: response?.data?.data?.attributes?.can_update_gantt_chart?.data?.map(
              (item) => item?.id
            ),
            can_view_gantt_chart: response?.data?.data?.attributes?.can_view_gantt_chart?.data?.map(
              (item) => item?.id
            ),
            can_update_project: response?.data?.data?.attributes?.can_update_project?.data?.map(
              (item) => item?.id
            ),
            can_view_project: response?.data?.data?.attributes?.can_view_project?.data?.map(
              (item) => item?.id
            ),
            vendors: response?.data?.data?.attributes?.vendors?.data?.map(
              (item) => item?.id
            ),
            sub_contractors:
              response?.data?.data?.attributes?.sub_contractors?.data?.map(
                (item) => item?.id
              ),
            agencies: response?.data?.data?.attributes?.agencies?.data?.map(
              (item) => item?.id
            ),
            start_date: moment(response?.data?.data?.attributes?.start_date),
            end_date: moment(response?.data?.data?.attributes?.end_date),
            city: response?.data?.data?.attributes?.city?.data?.id,
            state: response?.data?.data?.attributes?.state?.data?.id,
            project_type:
              response?.data?.data?.attributes?.project_type?.data?.id,
            company: response?.data?.data?.attributes?.company?.data?.id,
            location: response?.data?.data?.attributes?.location?.data?.id,
            actual_start_date: response?.data?.data?.attributes
              ?.actual_start_date
              ? moment(response?.data?.data?.attributes?.actual_start_date)
              : null,
            actual_end_date: response?.data?.data?.attributes?.actual_end_date
              ? moment(response?.data?.data?.attributes?.actual_end_date)
              : null,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log(data)

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;

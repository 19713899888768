import React, { useState, useEffect } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pie } from "@ant-design/plots";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [chartData, setChartData] = useState([]);

  const processData = (data) => {
    const projectCosts = {};

    data?.forEach((item) => {
      const projectName = item.attributes.project.data.attributes.name;
      const projectCost = item.attributes.grand_total_amount;

      if (projectCosts[projectName]) {
        projectCosts[projectName] += projectCost;
      } else {
        projectCosts[projectName] = projectCost;
      }
    });

    return Object.keys(projectCosts).map((key) => ({
      type: key,
      value: projectCosts[key],
    }));
  };

  const processGroupData = (data) => {
    const projectCosts = {};

    data?.forEach((item) => {
      const groupName = item.sub_contractor_group.data.attributes.name;
      const projectCost = item.grand_total_amount;

      if (projectCosts[groupName]) {
        projectCosts[groupName] += projectCost;
      } else {
        projectCosts[groupName] = projectCost;
      }
    });

    return Object.keys(projectCosts).map((key) => ({
      type: key,
      value: projectCosts[key],
    }));
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["estimated_amount:desc"],
      };

      const subQueryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      const subContractorResponse = await axios.get(
        `sub-contractors?${qs.stringify(subQueryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            sub_contractor_group: subContractorResponse?.data?.data?.find((sub) => sub.id === item.attributes.sub_contractor.data.id)?.attributes?.sub_contractor_group,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });

        const chartData = processData(response?.data?.data);

        setChartData(chartData)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          heading: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          remarks: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData({
      status: "Approved",
    });
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const totalCost = data?.reduce((acc, item) => acc + item.grand_total_amount, 0);

  // Add a total cost row
  const totalCostRow = {
    key: 'total',
    heading: '',
    updatedAt: '',
    project: null,
    sub_contractor: null,
    grand_total_amount: `Total Cost - ${totalCost.toLocaleString()}`
  };

  const pieConfig = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors
  };

  const SubPieChart = () => {
    const chartData = processGroupData(data);

    const pieConfig = {
      appendPadding: 10,
      data: chartData,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      label: {
        type: 'spider',
        labelHeight: 28,
        content: '{name}\n{percentage}',
      },
      interactions: [{ type: 'element-active' }],
      color: ['#CF6C58', '#2E943E', '#2E4494', '#4BC0C0'], // Custom colors
    };

    return <Pie {...pieConfig} />;
  };

  useEffect(() => {
    fetchData({
    status: "Approved",
  });
  }, []);

  console.log('work order', data)

  return (
    <>
      <ReportDataTable
        data={[...data, totalCostRow]}
        config={{
          ...config,
          project_title: '',
          pie_title: '',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div id="pieChart">
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p>Cost Break down for Project</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
            <Pie {...pieConfig} />
          </div>
        </div>
      </div>

      <div id="pieChart2">
        <div style={{ margin: '5rem 0', fontSize: '20px' }}>
          <p>Cost Break down for Subcontractor</p>
          <div style={{ border: '1px solid #E9E9E9', width: '60%', borderRadius: '10px', margin: '20px 0', padding: '10px' }}>
          <SubPieChart/>
          </div>
        </div>
      </div>

    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default Page;

import React, { useState, useEffect } from "react";
import { Input, Alert } from "antd";
import Papa from "papaparse";

const ALLOWED_EXTENSIONS = ["csv"];

const CsvImporter = ({ onChange = () => {} }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (e) => {
    setError("");

    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];

      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        setError("Please select a csv file");
        return;
      }

      setFile(inputFile);
    }
  };

  const handleParse = () => {
    if (!file) return setError("Enter a valid file");

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data?.map((record) => {
        record["estimated_budget"] = +record["estimated_budget"];
        record["sanctioned_budget"] = +record["sanctioned_budget"];
        record["assignees"] = record?.assignees?.split(", ");
        record["dependencies"] = record?.dependencies?.split(", ");

        delete record["project_no"];

        return record;
      });

      setData(parsedData);
      onChange(parsedData);
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    if (file) {
      handleParse();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div>
      <label style={styles.label}>CSV File</label>

      <div style={styles.inputGroup}>
        <Input onChange={handleFileChange} name="file" type="File" />
      </div>

      {file && data?.length > 0 && (
        <Alert
          message={`[${file?.name}] There are ${data?.length} tasks can be imported into the chart`}
          type="info"
          showIcon
        />
      )}

      {error && <Alert message={error} type="error" showIcon />}

      <div style={styles.tips}>
        <h4 style={styles.tipTitle}>Tips</h4>
        <p>
          The uploaded CSV file must conform to our predefined format. You can
          download the CSV template file{" "}
          <a
            href={`${process.env.REACT_APP_BASE_API_URL}/uploads/tasks_import_template_1d6b07f296.csv`}
            target="_blank"
            style={styles.tipLink}
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const styles = {
  label: {
    fontWeight: "bold",
    display: "block",
  },
  inputGroup: {
    marginBottom: 12,
  },
  alert: {
    marginTop: 12,
  },
  tips: {
    border: "1px solid #d9d9d9",
    background: "#eee",
    padding: 16,
    marginTop: 12,
  },
  tipTitle: {
    fontWeight: "bold",
  },
  tipLink: {
    textDecoration: "underline",
    color: "#40a9ff",
  },
};

export default CsvImporter;

import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Vendor",
      name: "vendor",
      placeholder: "Select vendor",
      required: true,
      rules: [
        {
          required: true,
          message: "Vendor is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "PO No",
    //   name: "po_no",
    //   required: true,
    //   placeholder: "Enter PO no",
    //   rules: [
    //     {
    //       required: true,
    //       message: "PO no is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "select",
      label: "Purchase Order",
      name: "purchase_order",
      required: true,
      placeholder: "Select purchase order",
      rules: [
        {
          required: true,
          message: "Purchase order is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Material Item",
      name: "material_item",
      placeholder: "Select material item",
      required: true,
      rules: [
        {
          required: true,
          message: "Material item is required!",
        },
      ],
    },
     // {
    //   span: 12,
    //   component: "select",
    //   label: "Material Unit",
    //   name: "material_unit",
    //   placeholder: "Select material unit",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Material unit is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "input",
      label: "EWAY No",
      name: "eway_no",
      required: true,
      placeholder: "Enter EWAY no",
      rules: [
        {
          required: true,
          message: "EWAY no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "GRN No",
      name: "grn_no",
      required: true,
      placeholder: "Enter GRN no",
      rules: [
        {
          required: true,
          message: "GRN no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "Vehicle No",
      name: "vehicle_no",
      required: true,
      placeholder: "Enter vehicle no",
      rules: [
        {
          required: true,
          message: "Vehicle no is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Order Date",
      name: "order_date",
      required: true,
      placeholder: "Select order date",
      rules: [
        {
          required: true,
          message: "Order date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Received Date",
      name: "received_date",
      required: true,
      placeholder: "Select received date",
      rules: [
        {
          required: true,
          message: "Received date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Select location",
      required: true,
      rules: [
        {
          required: true,
          message: "Location is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Received Quantity",
      name: "received_quantity",
      required: true,
      placeholder: "Enter received quantity",
      rules: [
        {
          required: true,
          message: "Received quantity is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Return/ Replace Quantity",
      name: "return_replace_quantity",
      required: true,
      placeholder: "Enter return/ replace quantity",
      rules: [
        {
          required: true,
          message: "Return/ Replace quantity is required!",
        },
      ],
      min: 0,
    },
    // {
    //   span: 12,
    //   component: "number",
    //   label: "Balance Quantity",
    //   name: "balance_quantity",
    //   // required: true,
    //   placeholder: "Enter balance quantity",
    //   // rules: [
    //   //   {
    //   //     required: true,
    //   //     message: "Balance quantity is required!",
    //   //   },
    //   // ],
    //   min: 0,
    //   disabled: true,
    // },
    {
      span: 12,
      component: "number",
      label: "Material Quality",
      name: "material_quality",
      // required: true,
      placeholder: "Enter material quality",
      // rules: [
      //   {
      //     required: true,
      //     message: "Material quality is required!",
      //   },
      // ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Corrective Actions",
      name: "corrective_actions",
      // required: true,
      placeholder: "Enter corrective actions",
      // rules: [
      //   {
      //     required: true,
      //     message: "Corrective actions is required!",
      //   },
      // ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Prompt Reply",
      name: "prompt_reply",
      // required: true,
      placeholder: "Enter prompt reply",
      // rules: [
      //   {
      //     required: true,
      //     message: "Prompt reply is required!",
      //   },
      // ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Delivery Quality",
      name: "delivery_quality",
      required: true,
      placeholder: "Enter delivery quality",
      rules: [
        {
          required: true,
          message: "Delivery quality is required!",
        },
      ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Quality Systems",
      name: "quality_systems",
      // required: true,
      placeholder: "Enter quality systems",
      // rules: [
      //   {
      //     required: true,
      //     message: "Quality systems is required!",
      //   },
      // ],
      min: 0,
    },
    {
      span: 12,
      component: "number",
      label: "Commercial Posture",
      name: "commercial_posture",
      // required: true,
      placeholder: "Enter commercial posture",
      // rules: [
      //   {
      //     required: true,
      //     message: "Commercial posture is required!",
      //   },
      // ],
      min: 0,
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        if (
          Number(values?.return_replace_quantity) >
          Number(values?.received_quantity)
        ) {
          return {
            success: false,
            message: `Return/ Replace quantity must not be greater than the received quantity!`,
          };
        }

        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});

import React from "react";
import { REPORTS } from "../constants";
import { Col, Row, Button } from "antd";
import { TbReportAnalytics } from "react-icons/tb";
import { SimpleGrid } from "@chakra-ui/react";

const BASE_MODULE_URL = "/quick-stocks";

const Card = ({ url, title }) => {
  return (
    <Col span={6}>
      <div style={styles.card}>
        <TbReportAnalytics style={styles.cardIcon} />
        <Button type="link" href={url} target="_blank">
          {title}
        </Button>
      </div>
    </Col>
  );
};

const DataTable = (props) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.headerTitle}>Reports</p>
      </div>

      <div style={styles.body}>
      <SimpleGrid columns={[1,2,3,4]}>
          {REPORTS.map((report) => (
            <Card
              title={report?.name}
              url={`${BASE_MODULE_URL}/${report?.id}`}
            />
          ))}

          <Card title="View All Reports" url={`${BASE_MODULE_URL}/reports`} />
        </SimpleGrid>
      </div>
    </div>
  );
};

const styles = {
  container: {
    boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
    borderRadius: 10,
    overflow: "hidden",
    marginBottom: 24,
  },
  header: {
    padding: "10px 15px",
    borderBottom: "1px solid #cf6c58",
    background: "#cf6c58",
  },
  headerTitle: {
    fontSize: "1.1rem",
    margin: "0rem",
    fontWeight: "500",
    color: "white",
  },
  body: {
    padding: 16,
    background: "white",
  },
  card: {
    display: "flex",
    alignItems: "center",
  },
  cardIcon: {
    fontSize: 24,
  },
};

export default DataTable;

import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-view`,
  title: `View ${config?.entityNames?.singular}`,
  pageTitle: `View ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-projects/dashboard",
      title: "Home",
    },
    {
      path: `/quick-projects/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `View ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Employee",
      name: "employee",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      required: true,
      placeholder: "Select date",
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
      disabled: true,
    },
    {
      span: 12,
      component: "uploader",
      label: "Attached Photo",
      name: "attached_photo",
      placeholder: "Upload attached photo",
      disabled: true,
    },
    {
      span: 24,
      component: "editor",
      label: "Details",
      name: "details",
      placeholder: "Enter details",
      rows: 5,
      disabled: true,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attached_photo",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.plural}`),
  },
  ...config,
});

import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Pending", "Active", "On Hold", "Completed"]?.map((item) => ({
      value: item,
      label: item,
    })),
    type: ["Task", "Milestone"]?.map((item) => ({
      value: item,
      label: item,
    })),
    all_tasks: [],
    all_milestones: [],
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          assignees: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
          // parent: data,
        })),
    },
    {
      slug: "machines",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          machines: data,
        })),
    },
    {
      slug: "hand-tools",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          hand_tools: data,
        })),
    },
    {
      slug: "tasks",
      query: {
        populate: "*",
      },
      onSuccess: (data) => {
        setValues((prev) => ({
          ...prev,
          dependencies: data?.map((item) => ({
            value: item?.id,
            label: item?.name,
            type: item?.type,
          })),
          all_tasks: data,
          all_milestones: data
            ?.filter((item) => item?.type === "Milestone")
            ?.map((item) => ({
              value: item?.id,
              label: item?.name,
              type: item?.type,
              project_id: item?.project?.data?.id,
            })),
        }));
      },
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const finalId = props?.mode === "embed" ? props?.embedId : id;

        const response = await axios.get(
          `/${config?.slugs?.plural}/${finalId}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            project: response?.data?.data?.attributes?.project?.data?.id,
            // parent: response?.data?.data?.attributes?.parent?.data?.id,
            start_date: moment(response?.data?.data?.attributes?.start_date),
            end_date: moment(response?.data?.data?.attributes?.end_date),
            machines: response?.data?.data?.attributes?.machines?.data?.map(
              (item) => item?.id
            ),
            hand_tools: response?.data?.data?.attributes?.hand_tools?.data?.map(
              (item) => item?.id
            ),
            dependencies:
              response?.data?.data?.attributes?.dependencies?.data?.map(
                (item) => item?.id
              ),
            assignees: response?.data?.data?.attributes?.assignees?.data?.map(
              (item) => item?.id
            ),
            actual_start_date: response?.data?.data?.attributes
              ?.actual_start_date
              ? moment(response?.data?.data?.attributes?.actual_start_date)
              : null,
            actual_end_date: response?.data?.data?.attributes?.actual_end_date
              ? moment(response?.data?.data?.attributes?.actual_end_date)
              : null,
            milestone: response?.data?.data?.attributes?.milestone?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.embedId]);

  return (
    <AddForm
      config={config}
      data={data}
      loading={loading}
      values={values}
      mode={props?.mode}
      onEmbedFinish={props?.onEmbedFinish}
      onEmbedCancel={props?.onEmbedCancel}
      disabledItems={props?.disabledItems}
      visibility={{
        milestone: (data) => {
          return data?.type === "Task";
        },
      }}
      onFieldChange={{
        type: (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          if (typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project?.data?.id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
            }));
          }
        },
        project: (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          if (typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project_id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
            }));
          }
        },
      }}
    />
  );
};

export default Page;

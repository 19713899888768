import React, { useState, useEffect } from "react";
import { Row, Col, Form, Space, Button } from "antd";
import FormElement from "../FormElement";

const ModalAddForm = ({
  loading = false,
  config,
  data = {},
  values = {},
  disabled = false,
  onSubmit = () => { },
  onCancel = () => { },
  modals,
  onFieldChange = {},
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleFinish = (values) => {
    setSubmitting(true);
    onSubmit(values);
    setSubmitting(false);
  };

  // Function to calculate total based on quantity, rate, and gst
  const calculateTotal = (quantity, rate, gst) => {
    const gstMultiplier = 1 + gst / 100;
    return quantity * rate * gstMultiplier;
  };
  // Function to calculate the GST amount
  const calculateGstAmount = (quantity, rate, gst) => {
    return (quantity * rate * gst) / 100;
  };

  useEffect(() => {
    form.setFieldsValue(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleValuesChange = (changedValues, allValues) => {
    const { quantity, rate, gst } = allValues;

    // Check if all necessary fields are available
    if (quantity && rate && gst) {
      const total = calculateTotal(quantity, rate, gst);
      const tax_amount = calculateGstAmount(quantity, rate, gst);
      const taxable_amount = calculateTotal(quantity, rate, gst) - calculateGstAmount(quantity, rate, gst);
      form.setFieldsValue({ total, taxable_amount, tax_amount });
    }
  };

  return (
    <Form
      form={form}
      name={config?.id}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={data}
      style={styles.form}
      scrollToFirstError
      disabled={disabled ? disabled : loading}
    >
      <Row gutter={24}>
        {config?.components.map((component) => (
          <Col span={component?.span} key={component?.name}>
            <FormElement
              {...component}
              values={values}
              form={form}
              modals={modals}
              onFieldChange={onFieldChange?.[component?.name]}
            />
          </Col>
        ))}
      </Row>

      <Space style={styles.footer}>
        <Button onClick={onCancel}>Cancel</Button>

        <Button type="primary" htmlType="submit" loading={submitting}>
          Save
        </Button>
      </Space>
    </Form>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  footer: {
    marginTop: 12,
  },
};

export default ModalAddForm;

import { STRAPI_URL } from "../../../../../constants";
import config from "../config";
import moment from "moment";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "MEETING_NOTES",
  breadcrumbs: [
    {
      path: "/quick-talks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-talks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Meeting",
      dataIndex: "meeting",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Photograph",
      dataIndex: "photograph_file",
      selectable: true,
      references: {
        fieldName: (record) => <img src={`${STRAPI_URL}${record?.url}`} alt="attachment" width={50} />,
      },
    },
    {
      title: "Start Time",
      dataIndex: "start",
      selectable: true,
      selectable: true,
      sorter: (a, b) =>
        moment(a.start_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix() -
        moment(b.start_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix(),
      // render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "End Time",
      dataIndex: "end",
      selectable: true,
      sorter: (a, b) =>
        moment(a.out_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix() -
        moment(b.out_time, "DD-MM-YYYY, HH:mm:ss.SSS").unix(),
      // render: (text) => <span>{text?.split(".")?.[0]}</span>,
      width: 250,
    },
    {
      title: "Employee",
      dataIndex: "employee",
      selectable: true,
      filterable: true,
      references: {
        fieldName: "first_name",
      },
    },
    {
      title: "Recording",
      dataIndex: "recording_file",
      selectable: true,
      references: {
        fieldName: (record) => <img src={`${STRAPI_URL}${record?.url}`} alt="attachment" width={50} />,
      },
    },
    {
      title: "Content",
      dataIndex: "content",
      selectable: true,

      render: (text) => <p dangerouslySetInnerHTML={{ __html: text }}></p>,

    },
  ],
  ...config,
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { makeConfig } from "./config";
import VendorQuotationView from "../../VendorQuotations/View";
import { Helmet } from "react-helmet";
import {
  Empty,
  PageHeader,
  Breadcrumb,
  Space,
  Button,
  Modal,
  notification,
  Spin,
  Input,
  Form,
  Select,
} from "antd";
import { axios } from "../../../../../App";
import qs from "qs";
import moment from "moment";
import Previewer from "./Previewer";

const { TextArea } = Input;
const { Option } = Select;

const Page = (props) => {
  const navigate = useNavigate();
  const config = makeConfig({ navigate });
  const { id } = useParams();
  const [vendors, setVendors] = useState([]);
  const [data, setData] = useState([]);
  const [activeVendorIndex, setActiveVendorIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [activeQuotation, setActiveQuotation] = useState({});
  const [showApprove, setShowApprove] = useState(false);
  const [approvalRemarks, setApprovalRemarks] = useState("");
  const [approvedBy, setApprovedBy] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [hasApprovedQuotation, setHasApprovedQuotation] = useState(false);

  const fetchEmployees = async () => {
    try {
      const queryObject = {
        populate: "*",
        filters: {
          is_deleted: false,
        },
      };

      const response = await axios.get(
        `/employees?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setEmployees(response?.data?.data);

        if (response?.data?.data?.length > 0) {
          setApprovedBy(response?.data?.data?.[0]?.id);
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchQuotations = async () => {
    try {
      setLoading(true);
      setVendors([]);
      setActiveVendorIndex(0);

      const queryObject = {
        populate: "*",
        filters: {
          vendor_material_requisition: id,
          // status: {
          //   $ne: "Approved",
          // },
        },
      };

      const response = await axios.get(
        `/vendor-quotations?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(response?.data?.data);

        const approvedQuotations = response?.data?.data?.filter(
          (item) => item?.attributes?.status === "Approved"
        );

        setActiveQuotation({
          id: response?.data?.data?.[0]?.id,
          ...response?.data?.data?.[0]?.attributes,
        });
        setHasApprovedQuotation(approvedQuotations?.length > 0);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDetails = () => setShowDetails((prev) => !prev);

  const toggleApprove = () => setShowApprove((prev) => !prev);

  const onViewDetails = (item) => () => {
    setActiveQuotation(item);
    toggleDetails();
  };

  const onSubmitApproval = async (values) => {
    try {
      const response = await axios.put(
        `/vendor-quotations/${activeQuotation?.id}`,
        {
          data: {
            approved_by: values?.approved_by,
            approval_remarks: values?.approval_remarks,
            status: "Approved",
          },
        }
      );
      if (response?.data) {
        resetApprove();
        fetchQuotations();

        notification["success"]({
          message: "Approve Quotation",
          description: "The quotation has been approved!",
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: "Approve Quotation",
        description: "Failed to approve the quotation!",
      });
    }
  };

  const resetApprove = () => {
    setShowApprove(false);
    setApprovalRemarks("");
    setApprovedBy(employees?.[0]?.id);
  };

  const onSelectQuotation = (e, record) => {
    e.stopPropagation();

    setActiveQuotation({
      id: record?.id,
      ...record?.attributes,
    });
  };

  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  useEffect(() => {
    fetchEmployees();
    fetchQuotations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={
          <span style={styles.pageTitle}>
            {config?.title}
          </span>
        }
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}

      {!loading && data?.length === 0 && <Empty />}

      {!loading && data?.length > 0 && (
        <>
          <Previewer data={activeQuotation} />

          <div style={styles.container}>
            {data?.map((record, index) => (
              <div
                key={index}
                style={{
                  ...styles.vendor,
                  border:
                    record?.id === activeQuotation?.id
                      ? "2px solid #017bfe"
                      : "2px solid #eee",
                }}
                onClick={(e) => onSelectQuotation(e, record)}
              >
                <h3 style={styles.vendorName}>
                  {record?.attributes?.vendor?.data?.attributes?.name}
                </h3>
                <p>
                  - <strong>Grand Total Amount ($):</strong>{" "}
                  {record?.attributes?.grand_total_amount || 0}
                </p>
                <p>
                  - <strong>Delivery Date:</strong>{" "}
                  {record?.attributes?.delivery_date
                    ? moment(record?.attributes?.delivery_date)?.format(
                        "DD-MM-YYYY"
                      )
                    : "N/A"}
                </p>
                <p>
                  - <strong>Delivery Address:</strong>{" "}
                  {getDeliveryAddress(
                    record?.attributes?.delivery_address?.data?.attributes ??
                      "N/A"
                  )}
                </p>

                <Space style={styles.buttons}>
                  <Button
                    block
                    onClick={onViewDetails({
                      id: record?.id,
                      ...record?.attributes,
                    })}
                  >
                    View Details
                  </Button>

                  {!hasApprovedQuotation &&
                    record?.attributes?.status !== "Approved" && (
                      <Button type="primary" block onClick={toggleApprove}>
                        Approve
                      </Button>
                    )}
                </Space>
              </div>
            ))}
          </div>
        </>
      )}

      <Modal
        title="Approve Quotation"
        visible={showApprove}
        onCancel={toggleApprove}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          h="max-content"
          onFinish={onSubmitApproval}
          autoComplete="off"
          layout="vertical"
          initialValues={{ approved_by: approvedBy }}
        >
          <Form.Item
            label="Remarks"
            name="approval_remarks"
            rules={[{ required: true, message: "Remarks is required!" }]}
          >
            <TextArea
              placeholder="Enter Remarks"
              value={approvalRemarks}
              onChange={(e) => setApprovalRemarks(e.target.value)}
              rows={3}
            />
          </Form.Item>

          <Form.Item
            label="Approved By"
            name="approved_by"
            rules={[{ required: true, message: "Approved by is required!" }]}
          >
            <Select value={approvedBy} onChange={setApprovedBy}>
              {employees?.map((employee) => (
                <Option value={employee?.id} key={employee.id}>
                  {employee?.attributes?.first_name}{" "}
                  {employee?.attributes?.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button htmlType="button" block onClick={toggleApprove}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {activeQuotation && (
        <Modal
          title="View Quotation Details"
          visible={showDetails}
          onCancel={toggleDetails}
          width={1200}
          footer={[<Button onClick={toggleDetails}>Cancel</Button>]}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
        >
          <VendorQuotationView
            showsHeader={false}
            showsCancel={false}
            inputId={activeQuotation?.id}
            mode="embed"
            {...props}
          />
        </Modal>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {},
  pageTitle: {
    whiteSpace: "normal", 
    overflow: "hidden",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: 24,
    border: "1px solid #ccc",
    padding: 24,
    maxHeight: 240,
    overflowY: "auto",
    background: "#eee",
  },
  vendor: {
    padding: "12px 16px",
    border: "1px solid #eee",
    cursor: "pointer",
    background: "white",
  },
  details: {
    margin: 0,
    padding: 0,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  buttons: {
    marginTop: 12,
    width: "100%",
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  detailsContainer: {
    border: "1px solid #eee",
  },
  vendorName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 6,
  },
};

export default Page;

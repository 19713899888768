import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData as fetchDataHelper } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { UserTypes } from "../constants";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    status: ["Active", "Inactive"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const [userTypeId, setUserTypeId] = useState(-1);
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchDataHelper(contentType);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          fetchDataHelper({
            slug: "app-roles",
            query: {
              filters: {
                user_type: {
                  $in: [response?.data?.data?.attributes?.user_type, "General"],
                },
              },
            },
            transformer: (item) => ({
              value: item?.id,
              label: item?.name,
            }),
            onSuccess: (data) =>
              setValues((prev) => ({
                ...prev,
                app_roles: data,
              })),
          });

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            app_roles: response?.data?.data?.attributes?.app_roles?.data?.map(
              (item) => item?.id
            ),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   if (userTypeId > -1) {
  //     fetchDataHelper({
  //       slug: "app-roles",
  //       query: {
  //         filters: {
  //           user_type: {
  //             $in: [
  //               UserTypes?.find((item) => item?.name === userTypeId)?.name,
  //               "General",
  //             ],
  //           },
  //         },
  //       },
  //       transformer: (item) => ({
  //         value: item?.id,
  //         label: item?.name,
  //       }),
  //       onSuccess: (data) =>
  //         setValues((prev) => ({
  //           ...prev,
  //           app_role: data,
  //         })),
  //     });
  //   }
  // }, [userTypeId]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;

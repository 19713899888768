import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import moment from 'moment';
import { v4 } from "uuid";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [client, setclient] = useState([]);
  const [paymentSchedule, setpaymentSchedule] = useState([]);
  const [shippings, setShippings] = useState([]);
  const [term, setterm] = useState([]);

  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor: data,
          approved_by: data,
        })),
    },

    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
          approved_by: data,
        })),
    },
    {
      slug: "companies",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          invoice_from: data,
        })),
    },
    {
      slug: "banks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          bank: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);

      setValues((prev) => ({
        ...prev,
        invoice_type: [
          {
            value: 'Performa Invoice',
            label: 'Performa Invoice'
          },
          {
            value: 'Tax Invoice',
            label: 'Tax Invoice'
          }
        ]
      }))
    });
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        console.log('response: ', response);

        if (response?.data) {
          const attributes = response?.data?.data?.attributes;

          setInvoices(attributes.invoices)

          const updatedClients = attributes?.invoice_to.map(client => ({
            ...client,
            po_date: moment(client.po_date), // Update top-level po_date
            raw_data: {
              ...client.raw_data,
              po_date: moment(client.raw_data?.po_date) // Update po_date inside raw_data
            }
          }));

          setclient(updatedClients);

          setShippings(attributes?.shipped_to)

          setterm(attributes?.payment_term)

          setData({
            id: response?.data?.data?.id,
            description: attributes.description,
            total_amount: attributes.total_amount,
            total_tax: attributes.total_tax,
            transportation: attributes.transportation,
            transportation_tax: attributes.transportation_tax,
            net_amount: attributes.net_amount,
            discount: attributes.discount,
            grand_total: attributes.grand_total,
            createdAt: moment(attributes.createdAt),
            updatedAt: moment(attributes.updatedAt),
            invoice_from: attributes.invoice_from?.data?.id,
            invoice_from_address: attributes.invoice_from_address,
            invoice_from_gstin_number: attributes.invoice_from_gstin_number,
            invoice_from_email: attributes.invoice_from_email,
            contact_person: attributes.contact_person,
            contact_person_number: attributes.contact_person_number,
            invoice_to: attributes.invoice_to,
            shipped_to: attributes.shipped_to,
            shipped_to_gstin_number: attributes.shipped_to_gstin_number,
            shipped_to_address: attributes.shipped_to_address,
            invoice_type: attributes.invoice_type,
            invoice_number: attributes.invoice_number,
            invoice_date: moment(attributes.invoice_date),
            po_number: attributes.po_number,
            po_date: moment(attributes.po_date),
            oc_number: attributes.oc_number,
            oc_date: moment(attributes.oc_date),
            invoices: attributes.invoices,
            bank: attributes.bank?.data?.id,
            account_name: attributes.account_name,
            account_number: attributes.account_number,
            branch: attributes.branch,
            ifc_number: attributes.ifc_number,
            micr_number: attributes.micr_number,
            swift_code: attributes.swift_code,
            payment_term: attributes.payment_term,
            payment_date: moment(attributes.payment_date),
            // attachment: attributes.attachment?.data,
            approved_by: attributes.approved_by?.data,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      raw_data: rawData,
    };
  };

  const onAddInvoice = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setInvoices((prev) => prev?.concat(newData));
  };

  const onEditInvoice = (id, data) => {
    let newItems = invoices?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setInvoices(newItems);
  };

  const onDeleteInvoice = (id) => {
    setInvoices((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddClient = (data) => {
    if (client?.length > 0) {
      alert('Only one client can be added.')
      return;
    }
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setclient((prev) => prev?.concat(newData));
  };

  const onEditClient = (id, data) => {
    let newitem = client?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setclient(newitem);
  };

  const onDeleteClient = (id) => {
    setclient((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddShipping = (data) => {
    if (shippings?.length > 0) {
      alert('Only one Shipping Address can be added.')
      return;
    }
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setShippings((prev) => prev?.concat(newData));
  };

  const onEditShipping = (id, data) => {
    let newitem = client?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setShippings(newitem);
  };

  const onDeleteShipping = (id) => {
    setShippings((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setterm((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newitem = term?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setterm(newitem);
  };

  const onDeleteTerm = (id) => {
    setterm((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);

      setValues((prev) => ({
        ...prev,
        invoice_type: [
          {
            value: 'Performa Invoice',
            label: 'Performa Invoice'
          },
          {
            value: 'Tax Invoice',
            label: 'Tax Invoice'
          }
        ]
      }))
    });
  }, []);

  const fetchVendorSchedulesData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        // sort: ["heading:asc"],
      };

      // if (!_.isEmpty(params)) {
      //   queryObject["filters"] = params;
      // }

      const response = await axios.get(
        `vendor-payment-schedules?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        // Create a set of material_group IDs from materialItems
        const materialGroupIds = new Set(invoices?.map(item => item.raw_data.material_group));

        // Filter the data based on material_group IDs
        const filtered_data = response?.data?.data?.filter(
          (s) => materialGroupIds?.has(s?.attributes?.material_group?.data?.id)
        );

        // console.log('Material IDS', materialGroupIds, response?.data?.data, filtered_data)

        if (filtered_data) {
          // Extract all payment schedules from the filtered data
          const payments = filtered_data.flatMap(payment => {
            const selected_item = invoices?.find(item => item.material_group === payment.attributes.material_group?.data?.id);
            return payment?.attributes?.payment_schedule?.map(schedule => ({
              ...schedule,
              material_group: payment.attributes.material_group,
              amount: selected_item?.total * Number(schedule?.amount_percentage) / 100
            }));
          });

          // Do something with the payments, e.g., set state
          setpaymentSchedule(payments);
        }


      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVendorSchedulesData();

  }, [invoices])

  console.log('created Incoice Edittttt', client, paymentSchedule)

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        invoices: invoices,
        invoice_to: client,
        payment_schedule: paymentSchedule,
        shipped_to: shippings,
        payment_term: term,
      }}
      loading={loading}
      values={values}
      modals={{
        invoices: {
          data: invoices,
          methods: {
            add: onAddInvoice,
            delete: onDeleteInvoice,
            edit: onEditInvoice,
          },
        },
        invoice_to: {
          data: client,
          methods: {
            add: onAddClient,
            delete: onDeleteClient,
            edit: onEditClient,
          },
        },
        payment_schedule: {
          data: paymentSchedule,
          methods: {
          },
        },
        shipped_to: {
          data: shippings,
          methods: {
            add: onAddShipping,
            delete: onDeleteShipping,
            edit: onEditShipping,
          },
        },
        payment_term: {
          data: term,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
            // addExisting: onAddExistingTerm,
          },
        },
      }}
    />
  );
};

export default Page;

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { axios } from '../../../../../App';
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pie } from "@ant-design/plots";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Table } from "antd";
import qs from "qs";
import { ReportDataTable } from '../../../components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const CashFlowTracker = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 1000,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [chartData, setChartData] = useState([]);
  const reportRefs = useRef([]);

  const generateDateRange = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];

    while (startDate.isSameOrBefore(endDate)) {
      dates.push(startDate.format('YYYY-MM-DD'));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  };

  const handleStartDateChange = (value) => {
    setSearchValues((prev) => {
      const updatedValues = { ...prev, start_date: value };
      if (updatedValues.start_date && updatedValues.end_date) {
        setDateRange(generateDateRange(updatedValues.start_date, updatedValues.end_date));
      }
      return updatedValues;
    });
  };

  const handleEndDateChange = (value) => {
    setSearchValues((prev) => {
      const updatedValues = { ...prev, end_date: value };
      if (updatedValues.start_date && updatedValues.end_date) {
        setDateRange(generateDateRange(updatedValues.start_date, updatedValues.end_date));
      }
      return updatedValues;
    });
  };

  const processData = (data) => {
    const projectCosts = {};

    data?.forEach((item) => {
      const projectName = item.attributes.project.data.attributes.name;
      const projectCost = item.attributes.grand_total_amount;

      if (projectCosts[projectName]) {
        projectCosts[projectName] += projectCost;
      } else {
        projectCosts[projectName] = projectCost;
      }
    });

    return Object.keys(projectCosts).map((key) => ({
      type: key,
      value: projectCosts[key],
    }));
  };


  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      const subQueryObject = {
        populate: "*",
        filters: {
          status: "Approved"
        }
      };



      const materia_response = await axios.get(
        `task-materials?${qs.stringify(queryObject)}`
      );

      const manpower_response = await axios.get(
        `task-manpowers?${qs.stringify(queryObject)}`
      );

      const subcontractorResponse = await axios.get(
        `sub-contractor-quotations?${qs.stringify(subQueryObject)}`
      );

      const agencyResponse = await axios.get(
        `agency-quotations?${qs.stringify(subQueryObject)}`
      );

      const machineResponse = await axios.get(
        `task-machines?${qs.stringify(queryObject)}`
      );


      setData(prev => ({
        materials: materia_response?.data?.data,
        manpower: manpower_response?.data?.data,
        sub_contractor: subcontractorResponse?.data?.data,
        agency: agencyResponse?.data?.data,
        machine: machineResponse?.data?.data
      }));


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          heading: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          remarks: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData({
      status: "Approved",
    });
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };


  const columns = [
    {
      title: 'Scheduled Date',
      dataIndex: 'scheduled_date',
      key: 'scheduled_date',
    },
    {
      title: 'Material',
      dataIndex: 'materialTotal',
      key: 'materialTotal',
    },
    {
      title: 'Manpower',
      dataIndex: 'manpowerTotal',
      key: 'manpowerTotal',
    },

    {
      title: 'Machine',
      dataIndex: 'machineTotal',
      key: 'machineTotal',
    },
    {
      title: 'Subcontractor',
      dataIndex: 'subTotal',
      key: 'subTotal',
    },
    {
      title: 'Agency',
      dataIndex: 'agencyTotal',
      key: 'agencyTotal',
    },
    {
      title: 'Construction Cost',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
  ];

  const dataSource = dateRange?.map(schedule => {
    const material_data = data?.materials?.filter(d => moment(d.attributes.createdAt).format('YYYY-MM-DD') == schedule);
    const machine_data = data?.machine?.filter(c => moment(c.attributes.createdAt).format('YYYY-MM-DD') == schedule);
    const manpower_data = data?.manpower?.filter(e => moment(e.attributes.createdAt).format('YYYY-MM-DD') == schedule);
    const sub_data = data?.sub_contractor?.filter(e => moment(e.attributes.createdAt).format('YYYY-MM-DD') == schedule);
    const agency_data = data?.agency?.filter(e => moment(e.attributes.createdAt).format('YYYY-MM-DD') == schedule);


    const total_material = material_data?.reduce((acc, item) => acc + item.attributes.amount, 0);
    const total_machine = machine_data?.reduce((acc, item) => acc + (item.attributes.machine.data.attributes.initial_value * item.attributes.quantity), 0);

    const total_manpower = manpower_data?.reduce((acc, item) => acc + (Number(item.attributes.manpower_type.data.attributes.cost_per_day) * Number(item.attributes.labour)), 0);
    const total_sub = sub_data?.reduce((acc, item) => acc + item.attributes.grand_total_amount, 0);
    const total_agency = agency_data?.reduce((acc, item) => acc + item.attributes.grand_total_amount, 0);


    // Calculate the total amount
    const totalAmount = total_sub + total_material + total_manpower + total_agency + total_machine;

    // Check if all the amounts are zero or falsy
    if (!total_material && !total_manpower && !total_agency && !total_sub && !total_machine) {
      return null; // Return null to filter out later
    }

    return {
      key: schedule,
      scheduled_date: schedule,
      materialTotal: `₹${total_material}` ?? '--',
      manpowerTotal: `₹${total_manpower}` ?? '--',
      subTotal: `₹${total_sub}` ?? '--',
      agencyTotal: `₹${total_agency}` ?? '--',
      machineTotal: `₹${total_machine}` ?? '--',
      total_amount: `₹${totalAmount}`,
    };
  }).filter(entry => entry !== null); // Filter out null entries

  const totalCost = dataSource?.reduce((acc, item) => acc + Number(item.total_amount?.split("₹")?.[1]), 0);

  // Add a total cost row
  const totalCostRow = {
    key: 'total',
    scheduled_date: '',
    materialTotal: '',
    manpowerTotal: '',
    subTotal: '',
    agencyTotal: '',
    machineTotal: '',
    total_amount: `Grand Total - ₹${totalCost.toLocaleString()}`
  };


  const SubTable = () => (

    <div id='barGraph' style={{ marginBottom: '20px' }}>
      <Table columns={columns} dataSource={[...dataSource, totalCostRow]} pagination={false} />
    </div>

  );

  useEffect(() => {
    fetchData({
      status: "Approved",
    });
  }, []);


  return (
    <>
      <ReportDataTable
        // data={[...dataSource, totalCostRow]}
        config={{
          pageTitle: 'Cash Flow Tracker Report',
          slugs: {
            plural: 'Cash Flow Tracker Report',
          },
          // columns: columns
        }}
        loading={loading}
        pagination={pagination}
        // actions={{
        //   onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        //   onRefresh: refreshData,
        // }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={handleStartDateChange}
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={handleEndDateChange}
                  value={searchValues?.end_date}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <SubTable />


    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    margin: '20px 0'
  }
};

export default CashFlowTracker;
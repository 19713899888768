import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { v4 } from "uuid";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
    approved_by: []
  });
  const config = makeConfig({ navigate });
  const [materialItems, setMaterialItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const contentTypes = [
    {
      slug: "material-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          material_group: data,
        })),
    },
    {
      slug: "vendors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendors: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "vendor-terms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_term: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  // MATERIAL ITEMS

  const transformMaterialItem = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddMaterialItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformMaterialItem(data),
    };

    setMaterialItems((prev) => prev?.concat(newData));
  };

  const onAddExistingMaterialItem = (data) => {
    const raw = {
      material_group: data?.material_group?.data?.id,
      material_item: data?.material_item?.data?.id,
      material_unit: data?.material_unit?.data?.id,
      quantity: data?.quantity,
      amount: data?.amount,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformMaterialItem(raw),
    };

    setMaterialItems((prev) => prev?.concat(newData));
  };

  const onEditMaterialItem = (id, data) => {
    let newItems = materialItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...record,
          ...transformMaterialItem(data),
        };
      }

      return record;
    });

    setMaterialItems(newItems);
  };

  const onDeleteMaterialItem = (id) => {
    setMaterialItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // PAYMENT SCHEDULES

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      scheduled_date: moment(rawData?.scheduled_date)?.format("DD-MM-YYYY"),
      raw_data: rawData,
    };
  };

  const onAddExistingPaymentSchedule = (data) => {
    const raw = {
      scheduled_date: data?.scheduled_date,
      amount: data?.amount,
      description: data?.description,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformPaymentSchedule(raw),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onAddPaymentSchedule = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onEditPaymentSchedule = (id, data) => {
    let newItems = paymentSchedules?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setPaymentSchedules(newItems);
  };

  const onDeletePaymentSchedule = (id) => {
    setPaymentSchedules((prev) => prev?.filter((item) => item?.id !== id));
  };

  // TERMS

  const transformTerm = (rawData) => {
    return {
      ...rawData,
      material_group: values?.material_group?.find(
        (item) => item?.value === rawData?.material_group
      )?.label,
      material_item: values?.material_item?.find(
        (item) => item?.value === rawData?.material_item
      )?.label,
      material_unit: values?.material_unit?.find(
        (item) => item?.value === rawData?.material_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onAddExistingTerm = (data) => {
    const raw = {
      heading: data?.heading,
      material_group: data?.material_group?.data?.id,
      material_item: data?.material_item?.data?.id,
      material_unit: data?.material_unit?.data?.id,
      remarks: data?.remarks,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformTerm(raw),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newItems = terms?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setTerms(newItems);
  };

  const onDeleteTerm = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setMaterialItems(
            response?.data?.data?.attributes?.material_items || []
          );
          setPaymentSchedules(
            response?.data?.data?.attributes?.payment_schedules || []
          );
          setTerms(response?.data?.data?.attributes?.terms || []);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            material_group:
              response?.data?.data?.attributes?.material_group?.data?.id,
            // vendors: response?.data?.data?.attributes?.vendor?.data?.id,
            vendors: response?.data?.data?.attributes?.vendors?.data?.map(
              (item) => item?.id
            ),
            project: response?.data?.data?.attributes?.project?.data?.id,
            // vendor_term:
            //   response?.data?.data?.attributes?.vendor_term?.data?.id,
            delivery_address:
              response?.data?.data?.attributes?.delivery_address?.data?.id,
            // scheduled_date: moment(
            //   response?.data?.data?.attributes?.scheduled_date
            // ),
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
            expected_delivery_date: moment(
              response?.data?.data?.attributes?.expected_delivery_date
            ),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        material_items: materialItems,
        payment_schedules: paymentSchedules,
        terms,
      }}
      loading={loading}
      values={values}
      modals={{
        material_items: {
          data: materialItems,
          methods: {
            add: onAddMaterialItem,
            edit: onEditMaterialItem,
            delete: onDeleteMaterialItem,
            addExisting: onAddExistingMaterialItem,
          },
        },
        payment_schedules: {
          data: paymentSchedules,
          methods: {
            add: onAddPaymentSchedule,
            edit: onEditPaymentSchedule,
            delete: onDeletePaymentSchedule,
            addExisting: onAddExistingPaymentSchedule,
          },
        },
        terms: {
          data: terms,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
            addExisting: onAddExistingTerm,
          },
        },
      }}
    />
  );
};

export default Page;

import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    is_loan: [true, false]?.map((item) => ({
      value: item,
      label: item === true ? "Yes" : "No",
    })),
    is_consumable: [true, false]?.map((item) => ({
      value: item,
      label: item === true ? "Yes" : "No",
    })),
  });
  const config = makeConfig({ navigate });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
        })),
    },
    {
      slug: "tool-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          tool_type: data,
        })),
    },
    {
      slug: "manufacturers",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          manufacturer: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            location: response?.data?.data?.attributes?.location?.data?.id,
            tool_type: response?.data?.data?.attributes?.tool_type?.data?.id,
            manufacturer:
              response?.data?.data?.attributes?.manufacturer?.data?.id,
            employee: response?.data?.data?.attributes?.employee?.data?.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <AddForm config={config} data={data} loading={loading} values={values} />
  );
};

export default Page;

import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Active", "On Hold", "Completed"]?.map((item) => ({
      value: item,
      label: item,
    })),
    type: ["Task", "Milestone"]?.map((item) => ({
      value: item,
      label: item,
    })),
    all_tasks: [],
    all_milestones: [],
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          assignees: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
          // parent: data,
        })),
    },
    {
      slug: "machines",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          machines: data,
        })),
    },
    {
      slug: "hand-tools",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          hand_tools: data,
        })),
    },
    {
      slug: "tasks",
      query: {
        populate: "*",
      },
      onSuccess: (data) => {
        setValues((prev) => ({
          ...prev,
          dependencies: data?.map((item) => ({
            value: item?.id,
            label: item?.name,
            type: item?.type,
          })),
          all_tasks: data,
          all_milestones: data
            ?.filter((item) => item?.type === "Milestone")
            ?.map((item) => ({
              value: item?.id,
              label: item?.name,
              type: item?.type,
              project_id: item?.project?.data?.id,
            })),
        }));
      },
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddForm
      config={config}
      values={values}
      mode={props?.mode}
      onEmbedFinish={props?.onEmbedFinish}
      onEmbedCancel={props?.onEmbedCancel}
      data={props?.data}
      disabledItems={props?.disabledItems}
      visibility={{
        milestone: (data) => {
          return data?.type === "Task";
        },
      }}
      onFieldChange={{
        type: (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          if (typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project?.data?.id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
            }));
          }
        },
        project: (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          if (typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project_id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
            }));
          }
        },
      }}
    />
  );
};

export default Page;



// import React, { useRef, useEffect, useState } from "react";
// import { PageHeader, Breadcrumb } from "antd";
// import config from "./config";
// import { useNavigate, Link } from "react-router-dom";
// import { Box } from "@chakra-ui/react";

// const Page = (props) => {
//   const navigate = useNavigate();
//   const iframeRef = useRef(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

//   const onBack = () => navigate(`/quick-stocks/reports`);

//   const setIframeHeight = () => {
//     const iframe = iframeRef.current;
//     if (iframe) {
//       iframe.style.height = window.innerWidth <= 600 ? '450px' : '690px';
//     }
//   };

//   useEffect(() => {
//     setIframeHeight();
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, [isMobile]);

//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 600);
//   };

//   return (
//     <Box>
//       <Breadcrumb style={styles.breadcrumbs}>
//         {config?.breadcrumbs?.map((breadcrumb, index) => (
//           <Breadcrumb.Item key={index}>
//             <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
//           </Breadcrumb.Item>
//         ))}
//       </Breadcrumb>

//       <PageHeader
//         title={config?.pageTitle}
//         style={styles.pageHeader}
//         onBack={onBack}
//       />

//       <Box w="100%" pt={4}>
//         <iframe
//           ref={iframeRef}
//           id="embeddedReport"
//           title="Agencies Report"
//           src="https://lookerstudio.google.com/embed/reporting/e12ce246-fd78-45ec-8116-55c5c4f4c6d5/page/DojqD"
//           width="100%"
//           // height="400px"
//           allowFullScreen
//           sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
//           style={{ display: isMobile ? 'none' : 'block', boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
//         />
//         {isMobile && (
//           <iframe
//             width="100%"
//             height="700px"
//             src="https://lookerstudio.google.com/embed/reporting/c413959d-f1a2-4758-8110-7d74c8e66292/page/DojqD"
//             frameborder="0"
//             style={{ boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)", borderRadius: 12 }}
//             allowfullscreen
//             sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
//           ></iframe>
//         )}
//       </Box>
//     </Box>
//   );
// };

// const styles = {
//   breadcrumbs: {},
//   pageHeader: {
//     padding: 0,
//     paddingBottom: 12,
//   },
// };

// export default Page;

import React, { useState, useEffect, forwardRef, useRef } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Table } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pie } from "@ant-design/plots";
import ReactToPrint from "react-to-print";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const componentRef = useRef();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});

  const [projectAgencies, setProjectAgencies] = useState([]);


  const fetchProjects = async () => {
    const queryObject = {
      populate: "*",
    };

    try {
      const response = await axios.get(`projects?${qs.stringify(queryObject)}`);

      if (response?.data?.data) {
        // Extract all subcontractors along with project names
        const all_subs_with_project_names = response.data.data.flatMap(project =>
          (project.attributes?.agencies?.data || []).map(agency => ({
            project_name: project.attributes.name,
            sub_contractor_name: agency.attributes.name,
            group_name: agency.attributes.sub_contractor_group?.data?.attributes?.name || 'N/A'
          }))
        );

        // Logging the subcontractors with project names for verification
        console.log('All subcontractors with project names:', all_subs_with_project_names);

        // Set the state with the extracted subcontractors and project names
        setProjectAgencies(all_subs_with_project_names);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          name: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          contact_person: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          address_line_1: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          address_line_2: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          pin_code: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          std_code: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          phone_number_1: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          phone_number_2: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          mobile_number_1: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          mobile_number_2: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          email_1: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          email_2: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          website: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          registration_no: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          pan_no: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          gsin_no: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          ifsc_code: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          micr_code: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          work_types: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          remarks: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const reportPdf = (letterheadFileName) => {

    return (
      <>
        <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
          <MyComponentToPrint
            config={{
              ...config,
              columns: config?.columns?.map((column) => ({
                ...column,
                ...getColumnProps(column),
              })),
            }}
            data={data}
            projectAgencies={projectAgencies}
            ref={componentRef}
            letterheadFileName={letterheadFileName}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
        </div>
      </>
    )
  }

  useEffect(() => {
    fetchData(filters);
    fetchProjects()
  }, []);

  const projects_data = projectAgencies?.map((s) => (
    {
      project_name: s.project_name,
      sub_contractor_name: s.sub_contractor_name,
      group_name: s.group_name,
    }
  ))

  const project_column = [
    {
      title: 'Project Name',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: 'Agencies Name',
      dataIndex: 'sub_contractor_name',
      key: 'sub_contractor_name',
    },
    {
      title: 'Agencies Group',
      dataIndex: 'group_name',
      key: 'group_name',
    },
  ]

  console.log('agency config', projectAgencies)

  return (
    <>
      <ReportDataTable
        data={data}
        projects_data={projects_data}
        projects_column={project_column}
        config={{
          ...config,
          project_title: 'Agency appointed for projects',
          pie_title: 'Agency and Agency groups',
          columns: config?.columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))
            ?.concat({
              title: "Actions",
              width: 100,
              selectable: false,
              render: ({ id }) => {
                return (
                  <Space>
                    <Tooltip
                      placement="bottom"
                      title={`View ${config?.entityNames?.singular}`}
                    >
                      <Button
                        icon={<EyeOutlined />}
                        onClick={() =>
                          navigate(
                            `/quick-stocks/${config?.slugs?.plural}/view/${id}`
                          )
                        }
                      />
                    </Tooltip>

                  </Space>
                );
              },
            }),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        pdfContent={reportPdf}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,
          // <Tooltip placement="top" title="Download Report">
          //   <Button icon={<DownloadOutlined />} onClick={downloadReport} />
          // </Tooltip>,
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div id="pieChart">
        <MyComponentToPrint data={data} />
      </div>

      <div style={{ fontSize: '20px'}}>
        <p style={{ paddingBottom: '20px' }}>Agency appointed for projects</p>
        <Table
          dataSource={projects_data}
          columns={project_column}
          pagination={false}
        // style={{ pageBreakInside: 'avoid' }}
        />
      </div>
      {/* {reportPdf()} */}
    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

const MyComponentToPrint = forwardRef((props, ref) => {

  const processData = (data) => {
    const groupCounts = data.reduce((acc, item) => {
      const groupName = item?.agency_group?.data?.attributes?.name;
      if (!acc[groupName]) {
        acc[groupName] = 0;
      }
      acc[groupName]++;
      return acc;
    }, {});

    return Object.keys(groupCounts).map(key => ({
      type: key,
      value: groupCounts[key]
    }));
  };

  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    setProcessedData(processData(props?.data));
  }, [props?.data]);

  const pieConfig = {
    appendPadding: 10,
    data: processedData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: {
      type: 'inner',
      offset: '-30%',
      content: '{value}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    color: ['#CF6C58', '#2E943E', '#2E4494', '#000000', '#4BC0C0'], // Custom colors
  };

  console.log('pie chart', processedData)

  return (

    <div style={{ margin: '5rem 0', fontSize: '20px'}}>
      {/* <p>Agency and Agency groups</p> */}
      <div style={{ border: '1px solid #E9E9E9', width: '50%', height: '400px', borderRadius: '10px', margin: '20px 0', padding: '10px'}}>
      <Pie {...pieConfig} />
      </div>
    </div>
  );
});

export default Page;

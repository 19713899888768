import React from "react";
import { Route } from "react-router-dom";
import {
  SubContractorList,
  SubContractorAdd,
  SubContractorEdit,
  SubContractorReport,
} from "../pages/SubContractors";
import SubContractorLedger from "../pages/SubcontractorLedger/Report";

export const register = (props) => {
  const basePath = "sub-contractors";

  return (
    <>
      <Route path={basePath} element={<SubContractorList />} exact={true} />
      <Route path={`${basePath}/add`} element={<SubContractorAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<SubContractorEdit />} />
      <Route path={`${basePath}-report`} element={<SubContractorReport />} />
      <Route path={`${basePath}-ledger-report`} element={<SubContractorLedger />} />
    </>
  );
};

import React, { useEffect, useState } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { axios } from "../../../../../App";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import qs from "qs";
import { v4 } from "uuid";
import moment from "moment";

const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    urgency_level: ["Low", "Medium", "High"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const config = makeConfig({ navigate });
  const [workItems, setWorkItems] = useState([]);
  const [paymentSchedules, setPaymentSchedules] = useState([]);
  const [terms, setTerms] = useState([]);
  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_item: data,
        })),
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },
    {
      slug: "agencies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agencies: data,
        })),
    },
    {
      slug: "projects",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
        })),
    },
    {
      slug: "agency-terms",
      transformer: (item) => ({
        value: item?.id,
        label: item?.heading,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency_terms: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          delivery_address: data,
        })),
    },
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
        //  approved_by: data,
        })),
    },
     {
      slug: "employees/approvers",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
         approved_by: data,
        })),
    },
  ];

  // WORK ITEMS

  const transformWorkItem = (rawData) => {
    return {
      ...rawData,
      work_group: values?.work_group?.find(
        (item) => item?.value === rawData?.work_group
      )?.label,
      work_item: values?.work_item?.find(
        (item) => item?.value === rawData?.work_item
      )?.label,
      work_unit: values?.work_unit?.find(
        (item) => item?.value === rawData?.work_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddWorkItem = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformWorkItem(data),
    };

    setWorkItems((prev) => prev?.concat(newData));
  };

  const onAddExistingWorkItem = (data) => {
    const raw = {
      work_group: data?.work_group?.data?.id,
      work_item: data?.work_item?.data?.id,
      work_unit: data?.work_unit?.data?.id,
      quantity: data?.quantity,
      amount: data?.amount,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformWorkItem(raw),
    };

    setWorkItems((prev) => prev?.concat(newData));
  };

  const onEditWorkItem = (id, data) => {
    let newItems = workItems?.map((record) => {
      if (record?.id === id) {
        record = {
          ...record,
          ...transformWorkItem(data),
        };
      }

      return record;
    });

    setWorkItems(newItems);
  };

  const onDeleteWorkItem = (id) => {
    setWorkItems((prev) => prev?.filter((item) => item?.id !== id));
  };

  // PAYMENT SCHEDULES

  const transformPaymentSchedule = (rawData) => {
    return {
      ...rawData,
      scheduled_date: moment(rawData?.scheduled_date)?.format("DD-MM-YYYY"),
      raw_data: rawData,
    };
  };

  const onAddExistingPaymentSchedule = (data) => {
    const raw = {
      scheduled_date: data?.scheduled_date,
      amount: data?.amount,
      description: data?.description,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformPaymentSchedule(raw),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onAddPaymentSchedule = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformPaymentSchedule(data),
    };

    setPaymentSchedules((prev) => prev?.concat(newData));
  };

  const onEditPaymentSchedule = (id, data) => {
    let newItems = paymentSchedules?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformPaymentSchedule(data),
        };
      }

      return record;
    });

    setPaymentSchedules(newItems);
  };

  const onDeletePaymentSchedule = (id) => {
    setPaymentSchedules((prev) => prev?.filter((item) => item?.id !== id));
  };

  // TERMS

  const transformTerm = (rawData) => {
    return {
      ...rawData,
      work_group: values?.work_group?.find(
        (item) => item?.value === rawData?.work_group
      )?.label,
      work_item: values?.work_item?.find(
        (item) => item?.value === rawData?.work_item
      )?.label,
      work_unit: values?.work_unit?.find(
        (item) => item?.value === rawData?.work_unit
      )?.label,
      raw_data: rawData,
    };
  };

  const onAddTerm = (data) => {
    const newData = {
      id: v4(),
      ...data,
      ...transformTerm(data),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onAddExistingTerm = (data) => {
    const raw = {
      heading: data?.heading,
      work_group: data?.work_group?.data?.id,
      work_item: data?.work_item?.data?.id,
      work_unit: data?.work_unit?.data?.id,
      remarks: data?.remarks,
    };

    const newData = {
      id: v4(),
      ...raw,
      ...transformTerm(raw),
    };

    setTerms((prev) => prev?.concat(newData));
  };

  const onEditTerm = (id, data) => {
    let newItems = terms?.map((record) => {
      if (record?.id === id) {
        record = {
          ...data,
          ...transformTerm(data),
        };
      }

      return record;
    });

    setTerms(newItems);
  };

  const onDeleteTerm = (id) => {
    setTerms((prev) => prev?.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/${config?.slugs?.plural}/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setWorkItems(response?.data?.data?.attributes?.work_items || []);
          setPaymentSchedules(
            response?.data?.data?.attributes?.payment_schedules || []
          );
          setTerms(response?.data?.data?.attributes?.terms || []);

          setData({
            ...response?.data?.data?.attributes,
            id: response?.data?.data?.id,
            work_group: response?.data?.data?.attributes?.work_group?.data?.id,
            agencies: response?.data?.data?.attributes?.agencies?.data?.map(
              (item) => item?.id
            ),
            project: response?.data?.data?.attributes?.project?.data?.id,
            delivery_address:
              response?.data?.data?.attributes?.delivery_address?.data?.id,
            approved_by:
              response?.data?.data?.attributes?.approved_by?.data?.id,
            expected_delivery_date: moment(
              response?.data?.data?.attributes?.expected_delivery_date
            ),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddForm
      config={config}
      data={{
        ...data,
        work_items: workItems,
        payment_schedules: paymentSchedules,
        terms,
      }}
      loading={loading}
      values={values}
      modals={{
        work_items: {
          data: workItems,
          methods: {
            add: onAddWorkItem,
            edit: onEditWorkItem,
            delete: onDeleteWorkItem,
            addExisting: onAddExistingWorkItem,
          },
        },
        payment_schedules: {
          data: paymentSchedules,
          methods: {
            add: onAddPaymentSchedule,
            edit: onEditPaymentSchedule,
            delete: onDeletePaymentSchedule,
            addExisting: onAddExistingPaymentSchedule,
          },
        },
        terms: {
          data: terms,
          methods: {
            add: onAddTerm,
            edit: onEditTerm,
            delete: onDeleteTerm,
            addExisting: onAddExistingTerm,
          },
        },
      }}
    />
  );
};

export default Page;

import React, { useState, useEffect } from "react";
import { ReportDataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const Page = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const { user } = useSelector((state) => state?.auth);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["createdAt:desc"],
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      const taskObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      const taskResponse = await axios.get(
        `tasks?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            full_task: taskResponse?.data?.data?.find((task) => task?.data?.id == item?.task?.data?.id),
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      $and: [
        {
          $or: [
            {
              description: {
                $containsi: searchValues?.keyword?.toLowerCase(),
              },
            },
            {
              createdAt: {
                $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
              },
            },
          ],
        },
        getFiltersForRoles(),
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(getFiltersForRoles());
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  const renderHeaderButtons = () => {
    let buttons = [
      <Tooltip placement="top" title="Refresh">
        <Button
          icon={<ReloadOutlined />}
          onClick={refreshData}
          loading={loading}
        />
      </Tooltip>,
    ];

    // if (data?.length > 0) {
    //   buttons.push(
    //     <Tooltip placement="top" title="Download Report">
    //       <Button icon={<DownloadOutlined />} onClick={downloadReport} />
    //     </Tooltip>
    //   );
    // }

    return buttons;
  };

  const getFiltersForRoles = () => {
    // const userType = user?.user_type?.toLowerCase()?.replace(/\s/i, "_");

    // if (user?.is_admin) {
    //   return {};
    // }

    // return {
    //   project: {
    //     [Pluralize(userType)]: {
    //       uid: user?.uid,
    //     },
    //   },
    // };

    if (user?.is_admin) {
      return {};
    }

    return {
      task: {
        assignees: {
          uid: user?.uid,
        },
      },
    };
  };

  useEffect(() => {
    fetchData(getFiltersForRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const man_power_columns = [
    {
      title: 'Project name',
      dataIndex: 'project_name',
      selectable: true,
      key: 'project_name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      selectable: true,
      key: 'date',
    },
    {
      title: 'Manpower Type',
      dataIndex: 'manpower_type',
      selectable: true,
      key: 'manpower_type',
    },

    {
      title: 'Cost Per Day',
      dataIndex: 'cost_per_day',
      selectable: true,
      key: 'cost_per_day',
    },
    {
      title: 'Total Cost',
      dataIndex: 'total-cost',
      selectable: true,
      key: 'total-cost',
    },
  ];

  const manpower_data = data?.map((man) => ({
    project_name: man?.full_task?.attributes?.project?.data?.attributes?.name,
    date: moment(man?.task?.data?.attributes?.date).format('YYYY-MM-DD'),
    manpower_type: man?.manpower_type?.data?.attributes?.name,
    cost_per_day: man?.manpower_type?.data?.attributes?.cost_per_day,
    'total-cost': man?.manpower_type?.data?.attributes?.cost_per_day * man?.labour,
  }))

  console.log('man power data', data, manpower_data)

  return (
    <>
      <ReportDataTable
        data={manpower_data}
        config={{
          ...config,
          columns: man_power_columns?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          })),
        }}
        loading={loading}
        pagination={pagination}
        actions={{
          onAdd: () => navigate(`/quick-projects/${config?.slugs?.plural}/add`),
          onRefresh: refreshData,
        }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={(value) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  value={searchValues?.endDate}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={renderHeaderButtons()}
        onBack={() => navigate(`/quick-projects/reports`)}
      />

    </>
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default Page;

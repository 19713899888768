import React from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });

  return (
    <ModalAddForm
      config={config}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
    />
  );
};

export default Page;

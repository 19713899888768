import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Active", "On Hold", "Completed"]?.map((item) => ({
      value: item,
      label: item,
    })),
  });
  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project_managers: data,
          members: data,
          // can_update_gantt_chart: data,
          // can_view_gantt_chart: data,
        })),
    },
    {
      slug: "app-users",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.username} (${item?.user_type})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          can_update_gantt_chart: data,
          can_view_gantt_chart: data,
          can_update_project: data,
          can_view_project: data,
        })),
    },
    {
      slug: "partners",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          partners: data,
        })),
    },
    // {
    //   slug: "vendors",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       vendors: data,
    //     })),
    // },
    {
      slug: "sub-contractors",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractors: data,
        })),
    },
    // {
    //   slug: "agencies",
    //   transformer: (item) => ({
    //     value: item?.id,
    //     label: item?.name,
    //   }),
    //   onSuccess: (data) =>
    //     setValues((prev) => ({
    //       ...prev,
    //       agencies: data,
    //     })),
    // },
    {
      slug: "project-types",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project_type: data,
        })),
    },
    {
      slug: "states",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        code: item?.code,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          state: data,
        })),
    },
    {
      slug: "cities",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    },
    {
      slug: "companies",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          company: data,
        })),
    },
    {
      slug: "locations",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.location_type?.data?.attributes?.name})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          location: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCities = (stateCode) => {
    const query = stateCode?.code
      ? { filters: { code: stateCode?.code } }
      : {};
      
    fetchData({
      slug: "cities",
      query: query,
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          city: data,
        })),
    });
  };

  const handleStateChange = (stateCode) => {
    fetchCities(stateCode);
  };

  return <AddForm config={config} values={values} onStateChange={handleStateChange} />;
};

export default Page;

import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
    id: `${config?.slugs?.plural}-edit`,
    title: `Edit New ${config?.entityNames?.singular}`,
    pageTitle: `Edit New ${config?.entityNames?.singular}`,
    breadcrumbs: [
        {
            path: "/quick-stocks/dashboard",
            title: "Home",
        },
        {
            path: `/quick-stocks/${config?.slugs?.plural}`,
            title: config?.entityNames?.plural,
        },
        {
            path: "",
            title: `Edit New ${config?.entityNames?.singular}`,
        },
    ],
    components: [
        {
            span: 12,
            component: "input",
            label: "HSN/SAC Code",
            name: "hsn_code",
            placeholder: "HSN/SAC Code",
            required: true,
            rules: [
                {
                    required: true,
                    message: "HSN/SAC code is required!",
                },
            ],
        },
        {
            span: 12,
            component: "number",
            label: "Quantity",
            name: "quantity",
            required: true,
            placeholder: "Enter Quantity",
            rules: [
                {
                    required: true,
                    message: "Quantity is required!",
                },
            ],
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "Rate",
            name: "rate",
            required: true,
            placeholder: "Enter Rate",
            rules: [
                {
                    required: true,
                    message: "Rate is required!",
                },
            ],
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "Taxable Amount",
            name: "taxable_amount",
            required: true,
            placeholder: "Enter Taxable Amount",
            rules: [
                {
                    required: true,
                    message: "Taxable Amount is required!",
                },
            ],
            min: 0,
        },
        {
            span: 12,
            component: "number",
            label: "GST%",
            name: "gst_percentage",
            required: true,
            placeholder: "Enter GST%",
            rules: [
                {
                    required: true,
                    message: "GST% is required!",
                },
            ],
            min: 0,
            max: 100,
        },
        {
            span: 12,
            component: "number",
            label: "Total",
            name: "total",
            required: true,
            placeholder: "Enter Total",
            rules: [
                {
                    required: true,
                    message: "Total is required!",
                },
            ],
            min: 0,
        },
        {
            span: 24,
            component: "editor",
            label: "Description",
            name: "description",
            placeholder: "Enter description",
            rows: 5,
        },
    ],
    actions: {
        onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
        onFinish: async (values) => {
            try {
                const response = await axios.put(`/${config?.slugs?.plural}`, {
                    data: {
                        ...values,
                        uid: v4(),
                        // creator: auth?.user?.id,
                    },
                });

                if (response?.data) {
                    return {
                        success: true,
                        message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
                    };
                }

                throw new Error();
            } catch (error) {
                return {
                    success: false,
                    message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
                };
            }
        },
    },
});

import React from "react";
import { Route } from "react-router-dom";
import {
  AgencyList,
  AgencyAdd,
  AgencyEdit,
  AgencyReport,
} from "../pages/Agencies";
import AgencyView from "../pages/Agencies/View";
import AgencyLedger from "../pages/AgenciesLedger/Report";

export const register = (props) => {
  const basePath = "agencies";

  return (
    <>
      <Route path={basePath} element={<AgencyList />} exact={true} />
      <Route path={`${basePath}/add`} element={<AgencyAdd />} />
      <Route path={`${basePath}/edit/:id`} element={<AgencyEdit />} />
      <Route path={`${basePath}/view/:id`} element={<AgencyView />} />
      <Route path={`${basePath}-report`} element={<AgencyReport />} />
      <Route path={`${basePath}-ledger-report`} element={<AgencyLedger />} />

    </>
  );
};

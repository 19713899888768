import React, { useState } from "react";
import { ModalAddForm } from "../../../components";
import { useNavigate } from "react-router-dom";
import { makeConfig } from "./config";

const Page = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const config = makeConfig({ navigate });

  return (
    <ModalAddForm
      config={config}
      data={props?.data}
      loading={loading}
      onSubmit={props?.onSubmit}
      onCancel={props?.onCancel}
    />
  );
};

export default Page;

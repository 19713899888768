import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";
import moment from "moment";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-teams/dashboard",
      title: "Home",
    },
    {
      path: `/quick-teams/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 8,
      component: "input",
      label: "First Name",
      name: "first_name",
      required: true,
      placeholder: "Enter first name",
      rules: [
        {
          required: true,
          message: "First name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "input",
      label: "Middle Name",
      name: "middle_name",
      placeholder: "Enter middle name",
    },
    {
      span: 8,
      component: "input",
      label: "Last Name",
      name: "last_name",
      required: true,
      placeholder: "Enter last name",
      rules: [
        {
          required: true,
          message: "Last name is required!",
        },
      ],
    },
    {
      span: 8,
      component: "date",
      label: "Date",
      name: "date",
      placeholder: "Select date",
    },
    {
      span: 8,
      component: "time",
      label: "In Time",
      name: "in_time",
      placeholder: "Select time",
    },
    {
      span: 8,
      component: "time",
      label: "Out Time",
      name: "out_time",
      placeholder: "Select time",
    },
    {
      span: 12,
      component: "input",
      type: "number",
      label: "Oxygen Level",
      name: "oxygen_level",
      placeholder: "Enter oxygen level",
    },
    {
      span: 12,
      component: "input",
      type: "number",
      label: "Temperature",
      name: "temperature",
      placeholder: "Enter temperature",
    },
    {
      span: 12,
      component: "select",
      label: "Meeting With",
      name: "meeting_with",
      placeholder: "Select meeting with",
    },
    {
      span: 12,
      component: "select",
      label: "Meeting Purpose",
      name: "meeting_purpose",
      placeholder: "Select meeting purpose",
    },
    {
      span: 12,
      component: "select",
      label: "Initial",
      name: "initial",
      placeholder: "Select initial",
      required: true,
      rules: [
        {
          required: true,
          message: "Initial is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Job Title",
      name: "job_title",
      placeholder: "Enter job title",
    },
    {
      span: 12,
      component: "select",
      label: "Designation",
      name: "designation",
      placeholder: "Select designation",
    },
    {
      span: 12,
      component: "select",
      label: "Location",
      name: "location",
      placeholder: "Enter location",
    },
    {
      span: 24,
      component: "input",
      label: "Company Name",
      name: "company",
      placeholder: "Enter company name",
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 1",
      name: "address_line_1",
      placeholder: "Enter address line 1",
    },
    {
      span: 24,
      component: "input",
      label: "Address Line 2",
      name: "address_line_2",
      placeholder: "Enter address line 2",
    },
    {
      span: 12,
      component: "select",
      label: "City",
      name: "city",
      placeholder: "Select city",
    },
    {
      span: 12,
      component: "select",
      label: "State",
      name: "state",
      placeholder: "Select state",
    },
    {
      span: 12,
      component: "input",
      label: "PIN code",
      name: "pin_code",
      placeholder: "Enter PIN code",
      rules: [
        {
          pattern: /^[0-9]{6}$/,
          message: "PIN code should be 6 digits",
        },
      ],
    },
    {
      span: 12,
      component: "input",
      label: "STD code",
      name: "std_code",
      placeholder: "Enter STD code",
    },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Phone Number 1",
    //   name: "phone_number_1",
    //   placeholder: "Enter phone number 1",
    // },
    // {
    //   span: 12,
    //   component: "input",
    //   label: "Phone Number 2",
    //   name: "phone_number_2",
    //   placeholder: "Enter phone number 2",
    // },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 1",
      name: "mobile_number_1",
      placeholder: "Enter mobile number 1",
    },
    {
      span: 12,
      component: "input",
      label: "Mobile Number 2",
      name: "mobile_number_2",
      placeholder: "Enter mobile number 2",
    },
    {
      span: 12,
      component: "input",
      label: "Email 1",
      name: "email_1",
      placeholder: "Enter email 1",
    },
    {
      span: 12,
      component: "input",
      label: "Email 2",
      name: "email_2",
      placeholder: "Enter email 2",
    },
    {
      span: 12,
      component: "uploader",
      label: "Photo",
      name: "photo",
      placeholder: "Upload photo",
    },
    {
      span: 24,
      component: "editor",
      label: "Description",
      name: "description",
      placeholder: "Enter description",
      rows: 5,
    },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "photo",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-teams/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            uid: v4(),
            // creator: auth?.user?.id,
            in_time: moment(values?.in_time).format("HH:mm:ss.SSS"),
            out_time: moment(values?.out_time).format("HH:mm:ss.SSS"),
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});

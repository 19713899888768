import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: `${config?.entityNames?.plural} Report`,
  code: "VENDORS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/reports`,
      title: "Reports",
    },
    {
      path: "",
      title: `${config?.entityNames?.plural} Report`,
    },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "name",
      selectable: true,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      width: 300,
    },
    {
      title: "Vendor Group",
      dataIndex: "vendor_group",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Company Type",
      dataIndex: "firm_type",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "City",
      dataIndex: "city",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "State",
      dataIndex: "state",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile_number_1",
      selectable: true,
      sorter: (a, b) => a?.mobile_number_1?.localeCompare(b?.mobile_number_1),
    },
  ],
  ...config,
};
